import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "movement-preview" }
const _hoisted_2 = { id: "advance-search" }
const _hoisted_3 = { class: "card mb-5 mb-xl-10" }
const _hoisted_4 = {
  class: "card-header border-0 cursor-pointer",
  role: "button",
  "data-bs-toggle": "collapse",
  "data-bs-target": "#contain-advance-search",
  "aria-expanded": "true",
  "aria-controls": "contain-advance-search"
}
const _hoisted_5 = { class: "card-title m-0" }
const _hoisted_6 = { class: "svg-icon svg-icon-2 svg-icon-gray-500 me-1" }
const _hoisted_7 = { class: "fw-bolder ml-2" }
const _hoisted_8 = {
  id: "contain-advance-search",
  class: "collapse hide m-10"
}
const _hoisted_9 = { class: "row g-9 mb-8" }
const _hoisted_10 = { class: "col-md-4 col-xs-12 fv-row" }
const _hoisted_11 = { class: "col-md-4 col-xs-12 fv-row" }
const _hoisted_12 = { class: "col-md-4 col-xs-12 fv-row" }
const _hoisted_13 = { class: "col-md-4 col-xs-12 fv-row" }
const _hoisted_14 = { class: "d-flex justify-content-end" }
const _hoisted_15 = {
  type: "submit",
  class: "btn btn-success"
}
const _hoisted_16 = { class: "svg-icon svg-icon-2 svg-icon-gray-500 me-1" }
const _hoisted_17 = { id: "movement-preview-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Datarow = _resolveComponent("Datarow")!
  const _component_Datatable = _resolveComponent("Datatable")!
  const _component_ModuleMaster = _resolveComponent("ModuleMaster")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
            ]),
            _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.$t('LABELS.ADVANCE_SERACH')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_Form, {
            as: "el-form",
            onSubmit: _ctx.searchMovements,
            ref: "form"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('LABELS.INVENTORY')), 1),
                  _createVNode(_component_InputText, { name: 'inventory' })
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('LABELS.ZONE')), 1),
                  _createVNode(_component_InputText, { name: 'zone' })
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('LABELS.DATE')), 1),
                  _createVNode(_component_DatePicker)
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('LABELS.DESCRIPTION')), 1),
                  _createVNode(_component_InputText, { name: 'description' })
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("button", _hoisted_15, [
                  _createElementVNode("span", _hoisted_16, [
                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('BUTTONS.SEARCH_MOVEMENTS')), 1)
                ])
              ])
            ]),
            _: 1
          }, 8, ["onSubmit"])
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_17, [
      _createVNode(_component_ModuleMaster, {
        numItemsPaginator: _ctx.totalRecords,
        itemsPerPage: _ctx.sizePage,
        requirePaginator: true,
        requireSearchField: false,
        useFilters: false,
        requireAddButton: false,
        fnCallbackChangePage: _ctx.handleChangePage,
        useExtraButtons: false
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Datatable, { headers: _ctx.headers }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.renderRows, (row) => {
                return (_openBlock(), _createBlock(_component_Datarow, {
                  key: row.ID,
                  dataRow: row
                }, null, 8, ["dataRow"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["headers"])
        ]),
        _: 1
      }, 8, ["numItemsPaginator", "itemsPerPage", "fnCallbackChangePage"])
    ])
  ]))
}