export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "BUTTONS": {
          "SEARCH_MOVEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar movimientos"])}
        },
        "TOOLTIPS": {
          
        },
        "LABELS": {
          "INVENTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventario"])},
          "ZONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zona"])},
          "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
          "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
          "ADVANCE_SERACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busqueda avanzada"])}
        }
      }
    }
  })
}
