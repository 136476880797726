
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import Datatable from "@/components/c-datatable/Datatable.vue";
import { formatToDatatable } from "@/common/formaterToDatatable";
import Datarow from "@/components/c-datatable/Datarow.vue";
import ModuleMaster from "@/components/c-module-master/module-master.vue";
import { FormTypes } from "@/core/enums/form-types";
import { debounce } from "lodash";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import InputText from "@/components/forms/InputText.vue"
import DatePicker from "@/components/forms/DatePicker.vue"
import { Form } from "vee-validate";

export default defineComponent({
    components:{
        Datatable
        , Datarow
        , ModuleMaster
        , DatePicker
        , InputText
        , Form
    },
    setup(){

        //PROPIEDADES
        const showFilters = ref(false);
        let modeForm = ref(FormTypes.Create);
        //moduleTable.SEARCH_TABLE_DATA();
        /**
         * CONFIGURACIONES DE LA TABLA
         */
        const configTable = reactive([
            { PropName: "id", HeadLabel: "Id", Type: "text", VisibleInGrid: false },
            { PropName: "folio", HeadLabel: "Folio", Type: "text", VisibleInGrid: true },
            { PropName: "type", HeadLabel: "Tipo de movimiento", Type: "text", VisibleInGrid: true, Badge: 'S' },//movementType -> Entrada/Salida
            { PropName: "note", HeadLabel: "Observación", Type: "text", VisibleInGrid: true },//observation
            { PropName: "date", HeadLabel: "Fecha", Type: "date", VisibleInGrid: true },          
            { PropName: "status", HeadLabel: "Estatus", Type: "text", VisibleInGrid: true, Badge: 'D' },          
        ]);   

        //METODOS
        const handleChangePage = (page: number) => {
            //moduleTable.CHANGE_PAGE({ currentPage: page });
            //console.log(`call handleChangePage(${page})`);   
        }


        const searchMovements = debounce(() => {
            //search movements
        }, 550, {leading: false, trailing: true}) 

        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const renderRows = computed(() => {
            return formatToDatatable([{
                id: 'A'
                , folio: '1W1'
                , type: 'Entrada'
                , note: 'media tonelada viene dañana'
                , date: '09/05/2023'
                , status: 'En transito'
            }], configTable);
        });

        onMounted(() => {
            setCurrentPageBreadcrumbs("Consulta de movimientos", ["Módulos","Invetario Fisico"]);
        })

        //DATOS DEL STORE
        const totalRecords = computed(() => 1);
        const sizePage = computed(() => 10);

        return {
            showFilters
            , totalRecords
            , sizePage
            , headers
            , renderRows
            , modeForm
            , searchMovements
            , handleChangePage
        }
    }
})
